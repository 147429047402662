import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FinvestLogo from "./assets/finvest-logo.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const defaultTheme = createTheme();

export default function EmailPreferences() {
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [email, setEmail] = useState("");
  const [preferences, setPreferences] = useState([
    { name: "Promotions", checked: true, label: "Promotions", description: "Limited-time offers and other promotional messages" },
    { name: "Surveys", checked: true, label: "Survey", description: "Invitation to share feedback with us" },
    { name: "Product Announcements", checked: true, label: "Product Announcements", description: "News and updates about Finvest" }
  ]);

  const navigate = useNavigate();

  const handlePreferenceChange = (event) => {
    const updatedPreferences = preferences.map(pref =>
      pref.name === event.target.name ? { ...pref, checked: event.target.checked } : pref
    );
    setPreferences(updatedPreferences);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/services/update-email-preferences`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          preferences: preferences.reduce((acc, pref) => {
            acc[pref.name] = pref.checked;
            return acc;
          }, {})
        }),
      });

      console.log(JSON.stringify({
        email: email,
        preferences: preferences.reduce((acc, pref) => {
          acc[pref.name] = pref.checked;
          return acc;
        }, {})
      }));

      if (!response.ok) {
        throw new Error("Error updating email preferences");
      } else {
        navigate("/accounts/emailpreferences/success");
      }
    } catch (error) {
      setSubmitError("Error updating email preferences");
      console.error("Submit Error:", submitError);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOptOut = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/services/update-email-preferences`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          preferences: preferences.reduce((acc, pref) => {
            acc[pref.name] = false;
            return acc;
          }, {})
        }),
      });

      console.log(JSON.stringify({
        email: email,
        preferences: preferences.reduce((acc, pref) => {
          acc[pref.name] = false;
          return acc;
        }, {})
      }));

      if (!response.ok) {
        throw new Error("Error opting out");
      } else {
        navigate("/accounts/emailpreferencesreset/success");
      }
    } catch (error) {
      console.error("Error opting out:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            padding: 3,
            // border: '2px solid #e0e0e0', 
            // borderRadius: '8px',
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={FinvestLogo}
            alt="Finvest Logo"
            style={{
              display: "block",
              margin: "0 auto",
              width: "150px",
              marginBottom: "15px",
            }}
          />
          <Typography
            component="h1"
            variant="h6"
            sx={{ fontWeight: "bold" }}
          >
            Manage your preferences
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={!!emailError}
              helperText={emailError}
              autoFocus
              value={email}
              onChange={handleEmailChange}
              sx={{ 
                mb: 3, 
                '& label.Mui-focused': {
                  color: '#3856DD',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#3856DD',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#3856DD',
                  }
                }
              }}
            />
            <Typography
            variant="body2"
            sx = {{ mb: 2, fontWeight: "bold" }}
          >
            Choose which emails would you want to receive:
          </Typography>
            {preferences.map(pref => (
              <Box key={pref.name} sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pref.checked}
                      onChange={handlePreferenceChange}
                      name={pref.name}
                      sx={{
                        color: 'grey',
                        '&.Mui-checked': {
                          color: '#3856DD',
                        }
                      }}
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {pref.label}
                    </Typography>
                  }
                />
                <Typography variant="body2" sx={{ ml: 4 }}>
                  {pref.description}
                </Typography>
              </Box>
            ))}
            <LoadingButton
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none', fontWeight: 'bold', backgroundColor: '#3856DD' }}
            >
              Update Preferences
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
