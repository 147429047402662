import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FinvestLogo from './assets/finvest-logo.png';

const defaultTheme = createTheme();

export default function EmailPreferenceResetConfirmation() {

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={FinvestLogo}
            alt="Finvest Logo"
            style={{ display: 'block', margin: '0 auto', width: '150px', marginBottom: '10px', }}
          />
          <Typography 
            component="h1" 
            variant="h6" 
            style={{
              fontWeight: 'bold',
              fontFamily: 'Roboto, sans-serif',
            }}
>
            Preferences updated successfully!
          </Typography>
          <Typography 
            component="h1" 
            variant="h5" 
            style={{
              fontWeight: 'bold',
              fontFamily: 'Roboto, sans-serif',
            }}
>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}