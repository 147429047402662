import React from 'react';
import ResetPassword from './ResetPassword';
import PasswordResetConfirmation from './PasswordResetConfirmation';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import EmailPreferenceResetConfirmation from './EmailPreferencesResetConfirmation';
import EmailPreferences from './EmailPreferences';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/accounts/passwordreset" element={<ResetPassword />} />
        <Route path="/accounts/passwordreset/success" element={<PasswordResetConfirmation />} />
        <Route path="/accounts/emailpreferences" element={<EmailPreferences />} />
        <Route path="/accounts/emailpreferences/success" element={<EmailPreferenceResetConfirmation />} />
      </Routes>
    </Router>
  );
}

export default App;
