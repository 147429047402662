import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import FinvestLogo from './assets/finvest-logo.png';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const defaultTheme = createTheme();

export default function ResetPassword() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const externalId = urlParams.get('id');

  const [passwordError, setPasswordError] = React.useState('');
  const [loading, setLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = () => {
    setPasswordError('');
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPassword = data.get('new_password');
    const confirmPassword = data.get('confirm_password');

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$&*~_])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
        setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and must be at least 8 characters in length.');
        return;
      }
  
      if (newPassword !== confirmPassword) {
        setPasswordError('Passwords do not match');
        return;
      }

    try {
      setLoading(true)
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reset-password`;
      console.log(apiUrl)
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reset_token: token,
          new_password: newPassword,
          external_user_id: externalId
        }),
      });

      if (!response.ok) {
        throw new Error('Error resetting password');
      } else if (response.ok) {
        navigate('/accounts/passwordreset/success');
      }
      
    } catch (error) {
      setSubmitError('Error resetting password');
      console.error('Submit Error:', submitError);
      setPasswordError('unable to change password');
      console.error('Error:', error);
    } finally {
      setLoading(false)
    }

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={FinvestLogo}
            alt="Finvest Logo"
            style={{ display: 'block', margin: '0 auto', width: '150px', marginBottom: '15px', }}
          />
          <Typography 
            component="h1"
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Create a New Password
          </Typography>
          <Typography
            align="center"
            variant="body2"
            sx = {{ mb: 2}}
          >
            Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character and must be atleast 8 characters in length
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="new_password"
              label="New Password"
              name="new_password"
              autoComplete="New Password"
              error={!!passwordError}
              helperText={passwordError}
              onChange={handlePasswordChange}
              autoFocus
              type={showPassword ? 'text' : 'password'}
              sx={{ 
                '& label.Mui-focused': {
                  color: '#3856DD',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#3856DD',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#3856DD',
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              id="confirm_password"
              autoComplete="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              sx={{ 
                '& label.Mui-focused': {
                  color: '#3856DD',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#3856DD',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#3856DD',
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none', fontWeight: 'bold', backgroundColor: '#3856DD' }}
            >
              Save Password
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}